import { trackPage } from 'src/utilities';
import React from 'react';

export const injTrackerBase = (pageName: string) => (WrappedComponent: React.ComponentType) =>
    class extends React.Component {
        componentDidMount() {
            trackPage(pageName);
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };

/**
 * Add tracking to a *page* component.
 * @param pageName string value for page user is visiting
 *
 * When the *page* component loads this wrapper fires off a `analytics.page()` call to track the page-load
 *
 * ## Example
 * ```
 * export const SomePage = compose(
 *     connectToState,
 *     injTracker('componentName'),
 * )(SomePageBase)
 * ```
 */
export const injTracker =
    (pageName: string) =>
    <P extends object>(WrappedComponent: React.ComponentType<any>) => {
        const component = injTrackerBase(pageName)(WrappedComponent);
        return component as React.ComponentType<P | object>;
    };
